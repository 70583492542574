export default async function (tag) {
  const route = useRoute();
  // if the tag is already in the query, remove it
  // maintain the category if it exists
  const query = { ...route.query };
  const tags = query.tags?.split(",") || [];
  const index = tags.indexOf(tag);
  if (index > -1) {
    tags.splice(index, 1);
  } else {
    tags.push(tag);
  }
  query.tags = tags.length ? tags.join(",") : null;
  await navigateTo({ path: "/notes", query });
}
